import { graphql, useStaticQuery } from 'gatsby'
import { FluidObject } from 'gatsby-image'

import { getLocalNodes } from '../helpers/locale'
export const useResourceMediaData = (key: string): { image: FluidObject; imageAlt: string } => {
  const { allContentfulResourceMedia } = useStaticQuery(
    graphql`
      query resourceMedia {
        allContentfulResourceMedia {
          nodes {
            id
            key
            node_locale
            media {
              title
              fluid {
                ...Image
              }
            }
          }
        }
      }
    `,
  )

  const resourceMediaLocal = getLocalNodes(allContentfulResourceMedia.nodes)
  const filteredResources = resourceMediaLocal.filter((r) => {
    return r.key === key
  })
  const media = filteredResources[0]?.media[0]

  return { image: media?.fluid, imageAlt: media?.title }
}
