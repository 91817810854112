import { graphql, useStaticQuery } from 'gatsby'

import { getLocalNodes } from '../helpers/locale'
export const useResourceSetData = (name: string, key: string, fallBack?: string): string => {
  const { allContentfulResourceSet } = useStaticQuery(
    graphql`
      query resourceSet {
        allContentfulResourceSet {
          nodes {
            name
            node_locale
            resources {
              ... on ContentfulResourceText {
                id
                key
                node_locale
                value {
                  value
                }
              }
            }
          }
        }
      }
    `,
  )

  const resourceSetLocal = getLocalNodes(allContentfulResourceSet.nodes)
  const resourceSet = resourceSetLocal.filter((r) => {
    return r.name === name
  })[0]

  const filteredResources = resourceSet.resources.filter((r) => {
    return r.key === key
  })

  return filteredResources || fallBack
}
