import Link from 'components/atoms/link'
import Select from 'components/atoms/select'
import Subhead from 'components/atoms/subhead'
import Container from 'components/container'
import Layout from 'components/layout'
import Breadcrumbs from 'components/molecules/breadcrumbs'
import Seo from 'components/molecules/seo'
import WholesaleForm from 'components/organisms/wholesaleForm'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { navigate } from 'gatsby-plugin-intl'
import { isJapanese, regions } from 'helpers/locale'
import { useRegionalTextData } from 'hooks/useRegionalTextData'
import { useResourceRichTextData } from 'hooks/useResourceRichTextData'
import { useResourceTextData } from 'hooks/useResourceTextData'
import * as React from 'react'
import styled, { css, CSSProp } from 'styled-components'
import { ContentfulWholesaleContactConnection, Site } from 'types/graphql-types'

const WholesaleContacts = styled.div`
  ${({ theme }): CSSProp => css`
    margin-top: 7rem;
    font-weight: ${theme.font.fontWeightLight};
    display: flex;
    @media only screen and ${theme.breakpoints.toLargeScreen} {
      font-size: 15px;
    }
    @media only screen and ${theme.breakpoints.toMediumScreen} {
      margin-top: 4rem;
    }
    @media only screen and ${theme.breakpoints.toNormalScreen} {
      display: block;
    }
    .copy {
      flex: 1;
      margin-bottom: 4rem;
      h2 {
        line-height: 1.4;
        font-size: 38px;
        ${isJapanese()
          ? css`
              font-family: ${theme.font.fontFamilyDefault};
            `
          : css`
              font-family: ${theme.font.fontFamilyAlt};
            `};
        font-weight: ${theme.font.fontWeightBold};
        padding-left: 0;
        padding-top: 0;
        padding-bottom: 0;
        @media only screen and ${theme.breakpoints.toLargeScreen} {
          font-size: 28px;
        }
        @media only screen and ${theme.breakpoints.toMediumScreen} {
          font-size: 22px;
          font-weight: normal;
        }
      }
      h2 {
        margin-bottom: 5rem;
        @media only screen and ${theme.breakpoints.toLargeScreen} {
          margin-bottom: 3rem;
        }
      }
      select {
        min-width: 26rem;
      }
    }
    .regions-wrap {
      flex: 1;
      margin-top: 3rem;
      @media only screen and ${theme.breakpoints.toLargeScreen} {
        margin-top: 1rem;
      }
    }
    .regions {
      display: flex;
      flex-wrap: wrap;
      @media only screen and ${theme.breakpoints.toMediumScreen} {
        margin-bottom: 0;
      }
    }
    .region {
      margin-bottom: 4rem;
      padding-right: 4rem;
      min-width: fit-content;
      max-width: 30rem;

      @media only screen and ${theme.breakpoints.fromTinyScreen} {
        width: 50%;
      }
      @media only screen and ${theme.breakpoints.fromSmallScreen} {
        width: 33%;
      }
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        width: 50%;
      }
    }
    .region-contact {
      a[href*='tel'] {
        text-decoration: none;
      }
    }
  `}
`

const CopyText = styled.div`
  margin-top: 5rem;
  margin-bottom: 5rem;
`

type WholeSaleTypes = PageProps & {
  data: {
    site: Site
    allContentfulWholesaleContact: ContentfulWholesaleContactConnection
    allContentfulRegionalText: {
      nodes: [
        {
          nzText: { nzText: string }
          auText: { auText: string }
          ukText: { ukText: string }
          sgText: { sgText: string }
          jpText: { jpText: string }
        }
      ]
    }
  }
  pageContext: {
    regionId: string
    region: {
      name: string
    }
  }
}

const WholesaleTeam = ({ data, pageContext }: WholeSaleTypes): JSX.Element => {
  return (
    <Layout>
      <Seo
        title={useResourceTextData('wholesale.metaTitle', 'Allpress Espresso')}
        postTitle={useResourceTextData(
          `wholesaleTeam.metaPostTitle-${pageContext.regionId}`,
          `Wholesale Coffee Supply | ${pageContext.region.name}`
        )}
        description={
          data.allContentfulRegionalText?.nodes[0][
            `${pageContext.regionId}Text`
          ][`${pageContext.regionId}Text`]
        }
      />
      <Container>
        <Breadcrumbs margin>
          <Link to="/wholesale/">
            {useResourceTextData('wholesale.title', 'Wholesale')}
          </Link>{' '}
          <span>-</span>
          {pageContext.region.name}
        </Breadcrumbs>
        <WholesaleContacts>
          <div className="copy">
            <div className="inner">
              <h2>
                {useResourceTextData(
                  'wholesaleTeam.title',
                  'Wholesale coffee for'
                )}
                <br /> {pageContext.region.name}
              </h2>
              <Subhead>
                {useResourceTextData(
                  'wholesaleTeam.inAnotherRegion',
                  'In another region?'
                )}
              </Subhead>
              <Select
                outline
                value={pageContext.regionId}
                onChange={e => {
                  navigate(`/wholesale/${regions[e.target.value].slug}`)
                }}
              >
                {Object.keys(regions).map(id => (
                  <option key={id} value={id}>
                    {regions[id].name}
                  </option>
                ))}
              </Select>
            </div>
          </div>
          <div className="regions-wrap">
            <div className="regions">
              {data.allContentfulWholesaleContact?.nodes.map((contact, k) => (
                <div className="region" key={`region-contact-${k}`}>
                  <Subhead>{contact.region}</Subhead>
                  <div className="region-contact">
                    {contact.phone && (
                      <>
                        <a href={`tel:${contact.phone}`}>{contact.phone}</a>
                        <br />
                      </>
                    )}
                    {contact.email && (
                      <a href={`mailto:${contact.email}`}>{contact.email}</a>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </WholesaleContacts>
      </Container>

      <WholesaleForm region={pageContext.regionId} />
      <Container>
        <CopyText>
          {useResourceRichTextData(
            `wholesaleTeamIntro${pageContext.regionId.toUpperCase()}`
          )}
        </CopyText>
      </Container>
    </Layout>
  )
}

export default WholesaleTeam

export const pageQuery = graphql`
  query WholeSaleTeamQuery($regionId: String, $locale: String) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulWholesaleContact(
      sort: { fields: region }
      filter: { siteRegionId: { eq: $regionId }, node_locale: { eq: $locale } }
    ) {
      nodes {
        email
        phone
        region
        node_locale
      }
    }
    allContentfulRegionalText(
      filter: {
        key: { eq: "wholesaleTeam.metaDescription" }
        node_locale: { eq: $locale }
      }
    ) {
      nodes {
        ...RegionalText
      }
    }
  }
`
