import { TextFieldStyles } from 'components/atoms/textField'
import theme from 'components/atoms/theme'
import styled, { css } from 'styled-components'

export const TextareaStyles = css`
  height: 15rem;
  font-family: ${theme.font.fontFamilyDefault};
  resize: none;
`

const Textarea = styled.textarea`
  ${TextFieldStyles}
  ${TextareaStyles}
`

export default Textarea